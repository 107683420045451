<template>
    <div class="setindex">
        <div class="buttonBox">
            <el-button type="text" icon="el-icon-back" @click="cancel"></el-button>
            <el-button @click="addClick">保存 </el-button>
            <!-- <el-button @click="cancel">取消</el-button> -->
            <el-button v-if="this.$route.params.id" @click="loadSql">加载SQL</el-button>
        </div>
        <div class="formBox">
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px" inline class="demo-ruleForm">
                <el-form-item label="数据集名称">
                    <el-input v-model="ruleForm.dsName" placeholder="数据集名称"></el-input>
                </el-form-item>
                 <el-form-item label="外键">
                    <el-input v-model="ruleForm.referCondition" placeholder="外键"></el-input>
                </el-form-item>
                 <el-form-item label="上级模块">
                    <el-select clearable v-model="ruleForm.parentId" placeholder="上级模块">
                        <el-option v-for="(item, index) in databaseMap" :key="index" :label="item.dsName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数据库链接">
                    <el-select clearable v-model="ruleForm.connId" placeholder="数据库链接" @change="connNameChange">
                        <el-option v-for="(item, index) in getdataBasedata" :key="index" :label="item.connName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数据集类型">
                    <el-select clearable v-model="ruleForm.dsType" placeholder="数据库链接">
                        <el-option label="基础数据集" value="基础数据集"></el-option>
                        <el-option label="聚合数据集" value="聚合数据集"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择数据集">
                    <el-select clearable multiple v-model="aggrDsIds" placeholder="数据库链接" @change="handleChange">
                        <el-option v-for="item in databaseMap" :key="item.id" :label="item.dsName" :value="item.id" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="SQL" class="textareainput">
                    <el-input type="textarea" v-model="ruleForm.sqlContent"></el-input>
                </el-form-item>
                <el-form-item label="顺序">
                    <el-input v-model="ruleForm.showOrder" placeholder="顺序"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <el-tabs @tab-click="tabClick" v-model="tabsValue" type="border-card" style="width: 100%; height: calc(50% - 20px);">
            <el-tab-pane label="字段属性" style="height: 100%" >
                <el-table
                    @cell-click="cellClick"
                    :row-class-name="tableRowClassName"
                    :data="shxiintableData"
                    border
                    style="width: 100%;"
                    height="100%"
                >
                    <el-table-column prop="showOrder" label="显示顺序">
                        <template slot-scope="scope">
                            <el-input
                                :ref="scope.row.id + 'showOrder'"
                                v-if="scope.row.index == rowIndex && cloumIndex == 'showOrder'"
                                v-model="scope.row.showOrder"
                                placeholder="显示顺序"
                                size="mini"
                                @blur="inputblur(scope.row)"
                            ></el-input>
                            <span v-else style="margin-left: 10px">{{ scope.row.showOrder }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fname" label="字段名称">
                        <template slot-scope="scope">
                            <el-input
                                :ref="scope.row.id + 'fname'"
                                v-if="scope.row.index == rowIndex && cloumIndex == 'fname'"
                                v-model="scope.row.fname"
                                placeholder="字段名称"
                                size="mini"
                                @blur="inputblur(scope.row)"
                            ></el-input>
                            <span v-else style="margin-left: 10px">{{ scope.row.fname }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="showName" label="显示名称">
                        <template slot-scope="scope">
                            <el-input
                                :ref="scope.row.id + 'showName'"
                                v-if="scope.row.index == rowIndex && cloumIndex == 'showName'"
                                v-model="scope.row.showName"
                                placeholder="显示名称"
                                size="mini"
                                @blur="inputblur(scope.row)"
                            ></el-input>
                            <span v-else style="margin-left: 10px">{{ scope.row.showName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ftype" label="字段类型">
                        <template slot-scope="scope">
                            <el-select
                                :ref="scope.row.id + 'ftype'"
                                size="mini"
                                v-if="scope.row.index == rowIndex && cloumIndex == 'ftype'"
                                v-model="scope.row.ftype"
                                placeholder="字段类型"
                                @change="inputblur(scope.row)"
                            >
                                <el-option label="varchar" value="0"></el-option>
                                <el-option label="number" value="1"></el-option>
                                <el-option label="date" value="2"></el-option>
                                <el-option label="decimal" value="3"></el-option>
                                <el-option label="text" value="4"></el-option>
                            </el-select>
                            <span v-else style="margin-left: 10px">{{ scope.row.ftype }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="filterType" label="查询符">
                        <template slot-scope="scope">
                            <el-select
                                :ref="scope.row.id + 'filterType'"
                                v-if="scope.row.index == rowIndex && cloumIndex == 'filterType'"
                                v-model="scope.row.filterType"
                                placeholder="查询符"
                                size="mini"
                                @change="inputblur(scope.row)"
                            >
                                <el-option label="模糊" value="0"></el-option>
                                <el-option label="等值" value="1"></el-option>
                                <el-option label="范围" value="2"></el-option>
                                <el-option label="比较" value="3"></el-option>
                                <el-option label="数据字典" value="4"></el-option>
                            </el-select>
                            <span v-else style="margin-left: 10px">{{ scope.row.filterType }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="colWidth" label="列宽">
                        <template slot-scope="scope">
                            <el-input
                                :ref="scope.row.id + 'colWidth'"
                                v-if="scope.row.index == rowIndex && cloumIndex == 'colWidth'"
                                v-model="scope.row.colWidth"
                                placeholder="列宽"
                                size="mini"
                                @blur="inputblur(scope.row)"
                            ></el-input>
                            <span v-else style="margin-left: 10px">{{ scope.row.colWidth }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="isQuery" width="100" label="是否查询" align="center">
                         <template slot-scope="scope">
                             <el-checkbox v-model="scope.row.isQuery" @change="changelistrow(scope.row)"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column prop="isFilter" width="100" label="是否筛选" align="center">
                        <template slot-scope="scope">
                             <el-checkbox v-model="scope.row.isFilter" @change="changelistsharow(scope.row)"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column prop="isTotal" width="100" label="是否合计" align="center">
                        <template slot-scope="scope">
                             <el-checkbox v-model="scope.row.isTotal" @change="changelistisSum(scope.row)"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column prop="isSubtotalGroup" width="150" label="是否小计分组" align="center">
                        <template slot-scope="scope">
                             <el-checkbox v-model="scope.row.isSubtotalGroup" @change="changelistisSum(scope.row)"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column prop="isSubtotal" width="100" label="是否小计" align="center">
                        <template slot-scope="scope">
                             <el-checkbox v-model="scope.row.isSubtotal" @change="changelistisSum(scope.row)"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" label="操作" align="center">
                        <template slot-scope="scope">
                             <!-- <el-checkbox v-model="scope.row.isFilter" @change="changelistsharow(scope.row)"></el-checkbox> -->
                             <el-button type="text" @click="setClick(scope.row)" icon="el-icon-edit"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="数据预览" style="height: 100%" >
                <el-table
                    :data="yulatableData" 
                    border
                    style="width: 100%;"
                    height="100%"
                    @header-dragend="headerDragend"
                >
                    <el-table-column
                        v-for="(item, index) in yulatableDatacloume" 
                        :key="index" 
                        :prop="item.fname" 
                        :label="item.showName"
                        :width="item.colWidth ? item.colWidth : '150'"
                        show-overflow-tooltip
                    ></el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <el-dialog title="修改" :visible.sync="dialogFormVisible">
            <el-form :model="form">
                <el-form-item label="显示顺序" :label-width="formLabelWidth">
                    <el-input v-model="form.showOrder" placeholder="显示顺序"></el-input>
                </el-form-item>
                <el-form-item label="字段名称" :label-width="formLabelWidth">
                    <el-input v-model="form.fname" placeholder="字段名称"></el-input>
                </el-form-item>
                <el-form-item label="显示名称" :label-width="formLabelWidth">
                    <el-input v-model="form.showName" placeholder="显示名称"></el-input>
                </el-form-item>
                <el-form-item label="字段类型" :label-width="formLabelWidth">
                    <el-select v-model="form.ftype" placeholder="字段类型">
                        <el-option label="varchar" value="0"></el-option>
                        <el-option label="number" value="1"></el-option>
                        <el-option label="date" value="2"></el-option>
                        <el-option label="decimal" value="3"></el-option>
                        <el-option label="text" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="查询符" :label-width="formLabelWidth">
                    <el-select v-model="form.filterType" placeholder="查询符">
                        <el-option label="模糊" value="0"></el-option>
                        <el-option label="等值" value="1"></el-option>
                        <el-option label="范围" value="2"></el-option>
                        <el-option label="比较" value="3"></el-option>
                        <el-option label="数据字典" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否查询" :label-width="formLabelWidth">
                    <el-checkbox v-model="form.isQuery"></el-checkbox>
                </el-form-item>
                <el-form-item label="是否筛选" :label-width="formLabelWidth">
                    <el-checkbox v-model="form.isFilter"></el-checkbox>
                </el-form-item>
                <el-form-item label="是否合计" :label-width="formLabelWidth">
                    <el-checkbox v-model="form.isTotal"></el-checkbox>
                </el-form-item>
                <el-form-item label="是否小计分组" :label-width="formLabelWidth">
                    <el-checkbox v-model="form.isSubtotalGroup"></el-checkbox>
                </el-form-item>
                <el-form-item label="是否小计" :label-width="formLabelWidth">
                    <el-checkbox v-model="form.isSubtotal"></el-checkbox>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisibleok">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getdataBaseLinklist, adddataSetlist, getdataBaseLinkDetailt, setDeiledataSetlist, getdataSetlistPage, getloadSql } from "@/api/dataSet/index.js"
import clone from "clone"
export default {
    name: 'setindex',
    data () {
        return{
            tabsValue: 0,
            rowIndex: 0,
            cloumIndex: '',
            rowValue: null,
            ruleForm: {
                dsName: null,//数据集名称
                connName: null,
                connId: null,//数据库链接
                dsType: null,//数据集类型
                sqlContent: null,//SQL
                id: '',
                aggrDsIds: '',//选择数据集
                aggrDsNames: [],
                showOrder: null,//顺序
                referCondition: null,//外键
                parentId: [],//上级模块
            },
            shxiintableData: [],
            yulatableData: [],
            yulatableDatacloume: [],
            getdataBasedata: [],
            form: {
                fname: null,
                showName: null,
                ftype: null,
                filterType: "",
                id: null,
                isQuery: false,
                isFilter: false,
                isTotal: false,
                isSubtotalGroup: null,
                isSubtotal: null,
            },
            dialogFormVisible: false,
            formLabelWidth: '110px',
            databaseMap: [],
            aggrDsIds: []
        }
    },
    mounted () {
        this.getdataBase()
        if (this.$route.params.id) {
            this.ruleForm = this.$route.params
            if (this.ruleForm.aggrDsIds !== "" && this.ruleForm.aggrDsIds !== null && this.ruleForm.aggrDsIds !== undefined) {
                this.aggrDsIds = this.ruleForm.aggrDsIds.split(',')
            }
            this.dateillist()
        }
    },
    methods: {
        tabClick (val) {
            if (this.tabsValue == 0) {
                this.dateillist()
            }
        },
        headerDragend (newWidth, odlwidth, column) {
            let cloumvalue = {}
            for (let i = 0; i < this.yulatableDatacloume.length; i ++) {
                if (this.yulatableDatacloume[i].fname === column.property) {
                    cloumvalue = this.yulatableDatacloume[i]
                }
            }
            cloumvalue.colWidth = Math.round(newWidth) + ""
            this.$nextTick(() => {
                if (cloumvalue.filterType == "模糊" || cloumvalue.filterType == "0") {
                    cloumvalue.filterType = 0
                } else if (cloumvalue.filterType == "等值" || cloumvalue.filterType == "1") {
                    cloumvalue.filterType = 1
                } else if (cloumvalue.filterType == "范围" || cloumvalue.filterType == "2") {
                    cloumvalue.filterType = 2
                } else if (cloumvalue.filterType ==  "比较" || cloumvalue.filterType == "3") {
                    cloumvalue.filterType = 3
                } else if (cloumvalue.filterType == "数据字典" || cloumvalue.filterType == "4") {
                    cloumvalue.filterType = 4
                } else {
                    cloumvalue.filterType = ""
                }
                if (cloumvalue.ftype == "varchar" || cloumvalue.ftype == "0") {
                    cloumvalue.ftype = 0
                } else if (cloumvalue.ftype == "number" || cloumvalue.ftype == "1") {
                    cloumvalue.ftype = 1
                } else if (cloumvalue.ftype == "date" || cloumvalue.ftype == "2") {
                    cloumvalue.ftype = 2
                } else if (cloumvalue.ftype ==  "decimal" || cloumvalue.ftype == "3") {
                    cloumvalue.ftype = 3
                } else if (cloumvalue.ftype == "text" || cloumvalue.ftype == "4") {
                    cloumvalue.ftype = 4
                } else {
                    cloumvalue.ftype = ""
                }
                setDeiledataSetlist(cloumvalue).then(res => {})
            })
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        cellClick (row, column) {
            if (column.property) {
                this.rowIndex = row.index
                this.cloumIndex = column.property
                this.rowValue = row[column.property]
                // this.$refs[row.id + column.property].focus()
                this.$nextTick(() => {
                    let idproperty = row.id + column.property
                    this.$refs[idproperty].focus()
                })
            }
        },
        inputblur (row) {
            console.log(row)
            if (this.rowValue !== row[this.cloumIndex]) {
                this.changelistrow(row)
            }
        },
        dialogFormVisibleok () {
            this.changelistrow(this.form)
            this.dialogFormVisible = false
        },
        changelistrow (row) {
            if (row.filterType == "模糊" || row.filterType == "0") {
                row.filterType = 0
            } else if (row.filterType == "等值" || row.filterType == "1") {
                row.filterType = 1
            } else if (row.filterType == "范围" || row.filterType == "2") {
                row.filterType = 2
            } else if (row.filterType ==  "比较" || row.filterType == "3") {
                row.filterType = 3
            } else if (row.filterType == "数据字典" || row.filterType == "4") {
                row.filterType = 4
            } else {
                row.filterType = ""
            }
            if (row.ftype == "varchar" || row.ftype == "0") {
                row.ftype = 0
            } else if (row.ftype == "number" || row.ftype == "1") {
                row.ftype = 1
            } else if (row.ftype == "date" || row.ftype == "2") {
                row.ftype = 2
            } else if (row.ftype ==  "decimal" || row.ftype == "3") {
                row.ftype = 3
            } else if (row.ftype == "text" || row.ftype == "4") {
                row.ftype = 4
            } else {
                row.ftype = ""
            }
            setDeiledataSetlist(row).then(res => {
                this.dateillist()
            })
        },
        changelistsharow (row) {
            this.changelistrow(row)
        },
        changelistisSum (row) {
            this.changelistrow(row)
        },
        setClick (row) {
            this.dialogFormVisible = true
            this.form = JSON.parse(JSON.stringify(row));
        },
        addClick () {
            let data = this.ruleForm
            if (data.aggrDsNames instanceof Array) {
                if (data.aggrDsNames.length == 0) {
                    data.aggrDsNames = null
                }
            }
            if(this.ruleForm.parentId !== null || this.ruleForm.parentId == 0) {
                if(this.ruleForm.referCondition == undefined || this.ruleForm.referCondition == '' || this.ruleForm.referCondition == null) {
                    this.$message.error('外键不能为空')
                } else {
                    adddataSetlist(data).then(res => {
                        if (res)  this.$message({ message: '保存成功', type: 'success' })
                        // this.$router.push({ name: "dataSet" })
                    })
                }
            } 
        },
        cancel () {
            this.$router.push({
                name: "dataSet"
            })
        },
        loadSql () {
            getloadSql(this.ruleForm).then(res => {
                this.$message({
                    message: '加载成功',
                    type: 'success'
                })
            })
        },
        handleChange(item){
            this.ruleForm.aggrDsNames = []
            this.ruleForm.aggrDsIds = []
            for(let i = 0; i < item.length;i++) {
                for(let j = 0; j < this.databaseMap.length; j++) {
                    if(item[i] == this.databaseMap[j].id) {
                        this.ruleForm.aggrDsIds.push(item[i])
                        this.ruleForm.aggrDsNames.push(this.databaseMap[j].dsName)
                    }
                }
            }
            this.ruleForm.aggrDsIds = this.ruleForm.aggrDsIds.join(',')
            this.ruleForm.aggrDsNames = this.ruleForm.aggrDsNames.join(',')
        },
        getdataBase () {
            getdataBaseLinklist().then(res => {
                this.getdataBasedata = res
            })
            let obj = { pageNum: 1, pageSize: 500, queryKey: '' }
            getdataSetlistPage(obj).then(res => {
                this.databaseMap = res.result
            })
        },
        connNameChange (selectParams) {
            for (let i = 0; i < this.getdataBasedata.length; i ++) {
                if (selectParams != "" && selectParams != null && selectParams != undefined) {
                    if (selectParams == this.getdataBasedata[i].id) {
                        this.ruleForm.connName = this.getdataBasedata[i].connName
                    }
                } else {
                    this.ruleForm.connName = ""
                }
            }
        },
        dateillist () {
            let ids = {
                id: ""
            }
            ids.id = this.$route.params.id
            this.yulatableDatacloume = []
            getdataBaseLinkDetailt(ids).then(res => {
                console.log(res);
                // 筛选项类型(0模糊、1等值、2范围、3比较、4数据字典)
                // 字段类型(0varchar 1number 2date 3decimal 4text)
                let data =  clone(res.fieldPropConfigList)
                for (let i = 0; i < data.length; i ++) {
                    if (data[i].filterType == 0) {
                        data[i].filterType = "模糊"
                    } else if (data[i].filterType == 1) {
                        data[i].filterType = "等值"
                    } else if (data[i].filterType == 2) {
                        data[i].filterType = "范围"
                    } else if (data[i].filterType == 3) {
                        data[i].filterType = "比较"
                    } else if (data[i].filterType == 4) {
                        data[i].filterType = "数据字典"
                    } else {
                        data[i].filterType = ""
                    }
                    if (data[i].ftype == 0) {
                        data[i].ftype = "varchar"
                    } else if (data[i].ftype == 1) {
                        data[i].ftype = "number"
                    } else if (data[i].ftype == 2) {
                        data[i].ftype = "date"
                    } else if (data[i].ftype == 3) {
                        data[i].ftype = "decimal"
                    } else if (data[i].ftype == 4) {
                        data[i].ftype = "text"
                    } else {
                        data[i].ftype = ""
                    }
                    if (data[i].isQuery == true) {
                        this.yulatableDatacloume.push(data[i])
                    }
                }
                
                this.shxiintableData = data
                this.yulatableData = res.previewDataList
            })
        }
    }
}
</script>
<style scoped lang="less">
.setindex{
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}
.buttonBox {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid black;
}
.formBox {
    padding: 10px;
    box-sizing: border-box;
}
.el-form-item {
    width: 340px;
}
.textareainput {
    width: 90%;
    /deep/ .el-form-item__content {
        width: 80%;
    }
}
/deep/ .el-textarea__inner {
    height: 140px;
}
.el-tabs--border-card {
    flex: 1;
}
/deep/ .el-tabs--border-card>.el-tabs__content {
    height: calc(85% - 20px);
    padding: 10px 10px 5px 10px;
}
</style>